import { css } from 'emotion';
import PropTypes from 'prop-types';
import React from 'react';

import { FaTwitter, FaFacebook, FaLinkedin, FaEnvelope } from 'react-icons/fa';

import { ShareButtonRectangle, ShareBlockStandard } from 'react-custom-share';

const CustomReactShare = props => {
  const { url, title, excerpt } = props;

  const customStyles = css`
    border-radius: 50% 0 50% 0;
    margin: 0 10px;
    flex-basis: 60px;
    height: 60px;
    flex-grow: 0;
  `;

  const shareBlockProps = {
    url: url,
    button: ShareButtonRectangle,
    buttons: [
      { network: 'Twitter', icon: FaTwitter },
      { network: 'Facebook', icon: FaFacebook },
      { network: 'Linkedin', icon: FaLinkedin },
      { network: 'Email', icon: FaEnvelope },
    ],
    text: title + '  >>>  ',
    longtext: excerpt,
    buttonCustomClassName: customStyles,
  };

  return <ShareBlockStandard {...shareBlockProps} />;
};

CustomReactShare.PropTypes = {
  url: PropTypes.string,
  title: PropTypes.string,
  excerpt: PropTypes.string,
};

CustomReactShare.defaultProps = {
  url: '',
  title: '',
  excerpt: '',
};

export default CustomReactShare;
