import React, { Component } from 'react';

class GoogleAds extends Component {
  componentDidMount() {
    (window.adsbygoogle = window.adsbygoogle || []).push({});
  }

  render() {
    return (
      <div>
        <ins
          className="adsbygoogle"
          key="1"
          style={{ display: 'block' }}
          data-ad-client="ca-pub-7504351777221493"
          data-ad-slot={this.props.slot}
          data-ad-format={this.props.format}
          data-full-width-responsive="true"
          data-ad-layout-key={this.props.lKey}
        ></ins>
      </div>
    );
  }
}

export default GoogleAds;
