import React from 'react';
import { Link, graphql } from 'gatsby';
import get from 'lodash/get';

import '../fonts/fonts-post.css';
import Bio from '../components/Bio';
import Layout from '../components/Layout';
import SEO from '../components/SEO';
import Panel from '../components/Panel';
import { formatPostDate, formatReadingTime } from '../utils/helpers';
import { rhythm, scale } from '../utils/typography';
import {
  codeToLanguage,
  createLanguageLink,
  getSlugWithLang,
  loadFontsForCode,
} from '../utils/i18n';
import { DiscussionEmbed } from 'disqus-react';
import CustomReactShare from '../components/CustomShareBlock';
import GoogleAds from '../components/GoogleAds';
import { languageTexts } from '../utils/languageTexts';

const systemFont = `system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif`;

class Translations extends React.Component {
  render() {
    let { translations, lang, languageLink, translatedLinks } = this.props;
    let readerTranslations = translations.filter(lang => lang !== 'tr');

    return (
      <div className="translations">
        <Panel style={{ fontFamily: systemFont }}>
          {translations.length > 0 && (
            <span>
              {lang == 'trr' && (
                <span>
                  Originally written in:{' '}
                  {'tr' === lang ? (
                    <b>{codeToLanguage('tr')}</b>
                  ) : (
                    <Link to={languageLink('tr')}>Türkçe</Link>
                  )}
                  <br />
                  <br />
                </span>
              )}
              <span>{languageTexts[lang]['translated_by']} </span>
              {readerTranslations.map((l, i) => (
                <React.Fragment key={l}>
                  {l === lang ? (
                    <b>{codeToLanguage(l)}</b>
                  ) : (
                    <Link to={getSlugWithLang(translatedLinks, l)}>
                      {codeToLanguage(l)}
                    </Link>
                  )}
                  {i === readerTranslations.length - 1 ? '' : ' • '}
                </React.Fragment>
              ))}
            </span>
          )}
          {lang !== 'tr' && (
            <div>
              <br />
              <div>
                <Link to={getSlugWithLang(translatedLinks, 'tr')}>
                  {languageTexts['tr']['read_original']}
                </Link>
                {' • '}
                <Link to={`/${lang}`}>
                  {languageTexts[lang]['all_translated']}
                </Link>{' '}
              </div>
            </div>
          )}
        </Panel>
      </div>
    );
  }
}

class BlogPostTemplate extends React.Component {
  render() {
    const post = this.props.data.markdownRemark;
    const siteTitle = get(this.props, 'data.site.siteMetadata.title');
    let {
      previous,
      next,
      slug,
      translations,
      translatedLinks,
    } = this.props.pageContext;

    const lang = post.fields.langKey;

    // Replace original links with translated when available.
    let html = post.html;
    translatedLinks.forEach(link => {
      // jeez
      function escapeRegExp(str) {
        return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
      }
      let translatedLink = '/' + lang + link.slug;
      html = html.replace(
        new RegExp('"' + escapeRegExp(link.slug) + '"', 'g'),
        '"' + translatedLink + '"'
      );
    });

    translations = translations.slice();
    translations.sort((a, b) => {
      return codeToLanguage(a) < codeToLanguage(b) ? -1 : 1;
    });

    let postLink = get(this.props, 'data.markdownRemark.fields.langKey');
    if (postLink == `tr`) {
      postLink = '';
    }
    postLink += get(this.props, 'data.markdownRemark.fields.slug');

    const excerpt = get(this.props, 'data.excerpt');
    const url = get(this.props, 'data.site.siteMetadata.siteUrl') + postLink;

    loadFontsForCode(lang);
    // TODO: this curried function is annoying
    const languageLink = createLanguageLink(slug, lang);

    const disqusShortname = 'hkucukcom';
    const disqusConfig = {
      identifier: post.id,
      title: post.frontmatter.title,
    };

    const editUrl = ``;
    const discussUrl = ``;
    let siteTags = ``;

    if (post.frontmatter.tags) {
      post.frontmatter.tags.map(
        (tag, i, arr) => (siteTags += tag + (arr.length - 1 !== i ? `, ` : ``))
      );
    }

    return (
      <Layout location={this.props.location} title={siteTitle} lang={lang}>
        <SEO
          lang={lang}
          title={post.frontmatter.title}
          description={post.frontmatter.spoiler}
          slug={post.fields.slug}
          tags={siteTags}
        />
        <main>
          <article>
            <header>
              <h1 style={{ color: 'var(--textTitle)' }}>
                {post.frontmatter.title}
              </h1>
              <p
                style={{
                  ...scale(-1 / 5),
                  display: 'block',
                  marginBottom: rhythm(1),
                  marginTop: rhythm(-4 / 5),
                }}
              >
                {formatPostDate(post.frontmatter.date, lang)}
                {` • ${formatReadingTime(post.timeToRead, lang)}`}

                {` • 🏷 `}
                {post.frontmatter.categories
                  ? post.frontmatter.categories.map((category, i, arr) => (
                      <Link
                        to={`/${languageTexts[lang]['url_category']}/${category}/`}
                        style={{
                          fontSize: '12px',
                          boxShadow: 'none',
                        }}
                      >
                        {category}
                        {arr.length - 1 !== i ? ', ' : null}
                      </Link>
                    ))
                  : null}
              </p>
              {translations.length > 0 && (
                <Translations
                  translations={translations}
                  editUrl={editUrl}
                  languageLink={languageLink}
                  translatedLinks={translatedLinks}
                  lang={lang}
                />
              )}
            </header>

            <GoogleAds slot="3255699011" lKey="" format="auto" />
            <br></br>
            <div
              dangerouslySetInnerHTML={{ __html: html }}
              style={{ textAlign: 'justify' }}
            />

            <footer>
              <br />
              <CustomReactShare
                title={post.frontmatter.title}
                excerpt={excerpt}
                url={url}
              />
              <br />
            </footer>
            <div
              style={{
                border: '1px solid #e7e9ee',
                padding: '0.3em',
              }}
            >
              <DiscussionEmbed
                shortname={disqusShortname}
                config={disqusConfig}
              />
            </div>
          </article>
        </main>
        <aside>
          <div
            style={{
              margin: '90px 0 40px 0',
              fontFamily: systemFont,
            }}
          ></div>
          <h3
            style={{
              fontFamily: 'Montserrat, sans-serif',
              marginTop: rhythm(0.25),
            }}
          >
            <Link
              style={{
                boxShadow: 'none',
                textDecoration: 'none',
                color: 'var(--pink)',
              }}
              to={'/'}
            >
              hkucuk
            </Link>
          </h3>
          <Bio
            langKey={get(this.props, 'data.markdownRemark.fields.langKey')}
          />
          <nav>
            <ul
              style={{
                display: 'flex',
                flexWrap: 'wrap',
                justifyContent: 'space-between',
                listStyle: 'none',
                padding: 0,
              }}
            >
              <li>
                {previous && (
                  <Link
                    to={previous.fields.slug}
                    rel="prev"
                    style={{ marginRight: 20 }}
                  >
                    ← {previous.frontmatter.title}
                  </Link>
                )}
              </li>
              <li>
                {next && (
                  <Link to={next.fields.slug} rel="next">
                    {next.frontmatter.title} →
                  </Link>
                )}
              </li>
            </ul>
          </nav>
        </aside>
      </Layout>
    );
  }
}

export default BlogPostTemplate;

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        author
        siteUrl
      }
    }
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      id
      html
      excerpt
      timeToRead
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        spoiler
        categories
        tags
        slug
        featuredImage {
          childImageSharp {
            fluid(maxWidth: 800) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
      fields {
        slug
        langKey
      }
    }
  }
`;
