import { languageTexts } from './languageTexts';

export function formatReadingTime(minutes, lang) {
  let cups = Math.round(minutes / 5);
  let bowls = 0;

  let txtMin = languageTexts[lang]['read_time'];

  if (cups > 5) {
    return `${new Array(Math.round(cups / Math.E))
      .fill('🍱')
      .join('')} ${minutes} ${txtMin}`;
  } else {
    return `${new Array(cups || 1).fill('☕️').join('')} ${minutes} ${txtMin}`;
  }
}

// `lang` is optional and will default to the current user agent locale
export function formatPostDate(date, lang) {
  if (typeof Date.prototype.toLocaleDateString !== 'function') {
    return date;
  }

  date = new Date(date);
  const args = [
    lang,
    { day: 'numeric', month: 'long', year: 'numeric' },
  ].filter(Boolean);
  return date.toLocaleDateString(...args);
}

export function capitalizeFirstLetter(str) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}
